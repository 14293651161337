<template>
  <div class="nav-main nav-main-menu">
    <ul class="list-unstyled nav">
      <li
        v-for="(item, index) in nav"
        :key="index"
      >
        <router-link
          :to="item.href"
          @click="closeMobileMenu"
        >
          <svgIcon
            name="arrow-link1"
            class="svg-icon icon-arrow-link"
          />
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
    import svgIcon from '@/components/SvgIcon.vue';
    export default {
        name: 'NavMain',
        components: {
            svgIcon,
        },
        data () {
            return {
                nav: [
                    {
                        name: 'Главная',
                        href: '/',
                    },
                    {
                        name: 'Victory WEB',
                        href: '/about',
                    },
                    {
                        name: 'Планирование',
                        href: '/planning',
                    },
                    // {
                    //     name: 'Сервисы',
                    //     href: '/services',
                    // },
                    {
                        name: 'Портфолио',
                        href: '/portfolio',
                    },
                    {
                        name: 'Контакты',
                        href: '/contacts',
                    }
                ]
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            closeMobileMenu() {
                this.$store.commit('Open_Sendwich', false);
                document.getElementsByTagName('body')[0].classList.remove('overlay-open');
            },
            // startLoader(name) {
            //     // console.log(name)
            //     if (name != '/fine-finish') {
            //         this.$parent.$parent.$refs.topProgress.start();
            //     }
            // }
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/nav-main/nav-main.scss';
    @import '@/styles/components/nav-main/nav-main-respond.scss';
</style>