import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './config/yandex.js'

import Maska from 'maska'
import './registerServiceWorker'
window.axios = require('axios');
window.$ = window.jQuery = require('jquery');

const app = createApp(App).use(store).use(router);
app.use(Maska);
app.mount('#app');