import { createRouter, createWebHistory} from 'vue-router'

const home = () => import( /* webpackChunkName: "home" */ '@/views/home.vue');
const about = () => import( /* webpackChunkName: "about" */ '@/views/about.vue');
const contacts = () => import( /* webpackChunkName: "contacts" */ '@/views/contacts.vue');
const services = () => import( /* webpackChunkName: "services" */ '@/views/services.vue');
const servicesWeb = () => import( /* webpackChunkName: "services-web" */ '@/components/services-page/pages/services-web-page.vue');
const servicesApp = () => import( /* webpackChunkName: "services-app" */ '@/components/services-page/pages/services-app-page.vue');
const servicesUi = () => import( /* webpackChunkName: "services-ui" */ '@/components/services-page/pages/services-ui-page.vue');
const planning = () => import( /* webpackChunkName: "planning" */ '@/views/planning.vue');
const portfolio = () => import( /* webpackChunkName: "partfolio" */ '@/views/portfolio.vue');
const portfolioCard = () => import( /* webpackChunkName: "partfolio-card" */ '@/components/portfolio-page/portfolio-card.vue');
const NotFound = () => import( /* webpackChunkName: "notFound" */ '@/views/404.vue');


const routes = [
    {
        path: '/',
        name: 'home',
        component: home
    },
    {
        path: '/about',
        name: 'about',
        component: about
    },
    {
        path: '/portfolio',
        name: 'portfolio',
        component: portfolio
    },
    {
        path: '/portfolio/:id',
        name: 'portfolio-card',
        component: portfolioCard
    },
    {
        path: '/planning',
        name: 'planning',
        component: planning
    },
    // {
    //     path: '/services',
    //     name: 'services',
    //     component: services,
    //     redirect: {name: 'services-web-mobile'},
    //     children: [
    //         {
    //           path: 'web-mobile',
    //           name: 'services-web-mobile',
    //           component: servicesWeb,
    //         },
    //         {
    //             path: 'app',
    //             component: servicesApp,
    //         },
    //         {
    //             path: 'ui-ux',
    //             component: servicesUi,
    //         },
    //       ],
    // },
    {
        path: '/contacts',
        name: 'contacts',
        component: contacts
    },
    { 
        path: '/404', 
        name: '404', 
        component: NotFound, 
    }, 
    { 
        path: '/:pathMatch(.*)*', 
        redirect: '/404'
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (from.name == 'portfolio-card' && to.name == 'portfolio') {
			return savedPosition
        } else {
			return {left: 0, top: 0}
        }
	},
})

export default router
