<template>
    <div id="landscapeMode" class="landscape-mode">
        <div class="logo-vweb">
            <img alt="victory web logo" :src="logo">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'landscapeMode',
        data () {
            return {
                logo: require('@/assets/images/logo.svg'),
            }
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/partials/landscape-mode.scss';
</style>