<template>
  <header
    id="header"
    class="header"
  >
    <div class="container">
      <div class="logo-vweb">
        <router-link to="/">
          <img
            alt="victory web logo"
            :src="logo"
          >
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
    import {mapGetters} from 'vuex'
    // import navMain from '@/components/nav-main/nav-main.vue'
    export default {
        name: 'AppHeader',
        components: {
            // navMain,
        },
        data () {
            return {
                logo: require('@/assets/images/logo.svg'),
            }
        },
        computed: mapGetters({
            openSendwich: 'loadOpenSendwich',
        }),
        beforeCreate() {
        },
        created () {
        },
        mounted () {
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/header/header.scss';
    // @import '@/styles/respond/header/respond-header.scss';
</style>