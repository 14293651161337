<template>
  <header
    id="header-mobile"
    class="header-mobile"
  >
    <div class="logo-vweb">
      <router-link to="/">
        <img
          alt="victory web logo"
          :src="logo"
        >
      </router-link>
    </div>
    <div
      class="m-menu"
      :class="{open: openSendwich}"
      @click="openMobileMenu(!openSendwich)"
    >
      <span />
    </div>
  </header>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'AppHeaderMobile',
        components: {
        },
        data () {
            return {
                logo: require('@/assets/images/logo-mobile.svg'),
            }
        },
        computed: mapGetters({
            openSendwich: 'loadOpenSendwich',
        }),
        beforeCreate() {
        },
        created () {
        },
        mounted () {
        },
        methods: {
            openMobileMenu(data) {
                this.$store.commit('Open_Sendwich', data);
                document.getElementsByTagName('body')[0].classList.toggle('overlay-open');
            },
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/header/header-respond.scss';
    // @import '@/styles/respond/header/respond-header.scss';
</style>