<template>
  <div class="btn-menu">
    <div class="container">
      <div
        class="mobile-menu"
        :class="{open: openSendwich}"
        @click="openMobileMenu(!openSendwich)"
      >
        <span />
      </div>
    </div>
  </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'BtnMenu',
        components: {
        },
        data () {
            return {
            }
        },
        computed: mapGetters({
            openSendwich: 'loadOpenSendwich',
        }),
        methods: {
            openMobileMenu(data) {
                this.$store.commit('Open_Sendwich', data);
                document.getElementsByTagName('body')[0].classList.toggle('overlay-open');
            },
            startLoader() {
                this.$parent.$refs.topProgress.start();
            },
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/partials/btn-menu.scss';
</style>