import { createStore } from 'vuex'

export default createStore({
    state: {
        isOpenSendwich: false,
        portfolioItems: [
            {
                name: 'bazis-a-corp',
                title: 'Bazis-A',
                desc: 'Корпоративный сайт компании',
                desc_full: 'Корпоративный сайт для крупнейшего застройщика в Казахсатне компании Bazis-A.',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: true,
                link_url: 'https://bazis.kz',
                prev_img: require('@/assets/images/partfolio/bazis-a-corp/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/bazis-a-corp/pic01.png'),
                    require('@/assets/images/partfolio/bazis-a-corp/pic02.png'),
                    require('@/assets/images/partfolio/bazis-a-corp/pic03.png'),
                    require('@/assets/images/partfolio/bazis-a-corp/pic04.png'),
                ]
            },
            {
                name: 'bazis-a-sales',
                title: 'Bazis-A Sales',
                desc: 'Сайт по продаже недвижимости',
                desc_full: 'Сайт по продаже надвижимости для строительной компании Bazis-A. В данном проекте был реализован полный спектр цифровых сервисов рекламы и продаж с использование систем храненеия данных компании Bazis-A, в том числе онлайн бронирование и продажа квартир.',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: true,
                link_url: 'https://sales.bazis.kz',
                prev_img: require('@/assets/images/partfolio/bazis-a-sales/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/bazis-a-sales/pic01.png'),
                    require('@/assets/images/partfolio/bazis-a-sales/pic02.png'),
                    require('@/assets/images/partfolio/bazis-a-sales/pic03.png'),
                    require('@/assets/images/partfolio/bazis-a-sales/pic04.png'),
                ]
            },
            {
                name: 'bazis-a-portal',
                title: 'Bazis-A Портал Закупок',
                desc: 'Портал закупок и тендеров',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: true,
                link_url: 'https://portal.bazis.kz',
                prev_img: require('@/assets/images/partfolio/bazis-a-portal/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/bazis-a-portal/pic01.png'),
                    require('@/assets/images/partfolio/bazis-a-portal/pic02.png'),
                    require('@/assets/images/partfolio/bazis-a-portal/pic03.png'),
                    require('@/assets/images/partfolio/bazis-a-portal/pic04.png'),
                ]
            },
            {
                name: 'skypark',
                title: 'Sky Park',
                desc: 'Сайт для ЖК Sky Park',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: true,
                link_url: 'https://skypark.bazis.kz',
                prev_img: require('@/assets/images/partfolio/skypark/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/skypark/pic01.png'),
                    require('@/assets/images/partfolio/skypark/pic02.png'),
                    require('@/assets/images/partfolio/skypark/pic03.png'),
                    require('@/assets/images/partfolio/skypark/pic04.png'),
                ]
            },
            {
                name: 'soho',
                title: 'SOHO',
                desc: 'Сайт-лендинг для ЖК SOHO',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: true,
                link_url: 'https://soho.bazis.kz',
                prev_img: require('@/assets/images/partfolio/soho/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/soho/pic01.png'),
                    require('@/assets/images/partfolio/soho/pic02.png'),
                    require('@/assets/images/partfolio/soho/pic03.png'),
                    require('@/assets/images/partfolio/soho/pic04.png'),
                ]
            },
            {
                name: 'monaco',
                title: 'MONACO',
                desc: 'Сайт-лендинг для ЖК MONACO',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: true,
                link_url: 'https://monaco.bazis.kz',
                prev_img: require('@/assets/images/partfolio/monaco/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/monaco/pic01.png'),
                    require('@/assets/images/partfolio/monaco/pic02.png'),
                    require('@/assets/images/partfolio/monaco/pic03.png'),
                    require('@/assets/images/partfolio/monaco/pic04.png'),
                ]
            },
            {
                name: 'metropole',
                title: 'Metropole',
                desc: 'Сайт-лендинг для ЖК METROPOLE',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: true,
                link_url: 'https://metropole.bazis.kz',
                prev_img: require('@/assets/images/partfolio/metropole/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/metropole/pic01.png'),
                    require('@/assets/images/partfolio/metropole/pic02.png'),
                    require('@/assets/images/partfolio/metropole/pic03.png'),
                    require('@/assets/images/partfolio/metropole/pic04.png'),
                ]
            },
            {
                name: 'remizovka',
                title: 'Remizovka',
                desc: 'Сайт-лендинг для ЖК REMIZOVKA',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: true,
                link_url: 'https://remizovka.bazis.kz',
                prev_img: require('@/assets/images/partfolio/remizovka/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/remizovka/pic01.png'),
                    require('@/assets/images/partfolio/remizovka/pic02.png'),
                    require('@/assets/images/partfolio/remizovka/pic03.png'),
                    require('@/assets/images/partfolio/remizovka/pic04.png'),
                ]
            },
            {
                name: 'orion',
                title: 'Orion',
                desc: 'Сайт-лендинг для ЖК ORION',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/orion/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/orion/pic01.png'),
                    require('@/assets/images/partfolio/orion/pic02.png'),
                    require('@/assets/images/partfolio/orion/pic03.png'),
                    require('@/assets/images/partfolio/orion/pic04.png'),
                ]
            },
            {
                name: 'kohota',
                title: 'Koh Ota',
                desc: 'Сайт-лендинг для ЖК Koh Ota',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/kohota/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/kohota/pic01.png'),
                    require('@/assets/images/partfolio/kohota/pic02.png'),
                    require('@/assets/images/partfolio/kohota/pic03.png'),
                    require('@/assets/images/partfolio/kohota/pic04.png'),
                ]
            },
            {
                name: 'primavera',
                title: 'Primavera',
                desc: 'Сайт-лендинг для ЖК Primavera',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/primavera/pic-prev.png'),
                rules: {
                    design: true,
                    code: false,
                    responsive: false,
                },
                images: [
                    require('@/assets/images/partfolio/primavera/pic01.png'),
                    require('@/assets/images/partfolio/primavera/pic02.png'),
                    require('@/assets/images/partfolio/primavera/pic03.png'),
                    require('@/assets/images/partfolio/primavera/pic04.png'),
                ]
            },
            {
                name: 'alfarabi27',
                title: 'Al-Farabi 27',
                desc: 'Сайт-лендинг для ЖК Al-Farabi 27',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/alfarabi27/pic-prev.png'),
                rules: {
                    design: true,
                    code: false,
                    responsive: false,
                },
                images: [
                    require('@/assets/images/partfolio/alfarabi27/pic01.png'),
                    require('@/assets/images/partfolio/alfarabi27/pic02.png'),
                    require('@/assets/images/partfolio/alfarabi27/pic03.png'),
                    require('@/assets/images/partfolio/alfarabi27/pic04.png'),
                ]
            },
            {
                name: 'aqualina',
                title: 'Aqualina',
                desc: 'Сайт-лендинг для ЖК Aqualina',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/aqualina/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/aqualina/pic01.png'),
                    require('@/assets/images/partfolio/aqualina/pic02.png'),
                    require('@/assets/images/partfolio/aqualina/pic03.png'),
                    require('@/assets/images/partfolio/aqualina/pic04.png'),
                ]
            },
            {
                name: 'holland',
                title: 'Holland',
                desc: 'Сайт-лендинг для ЖК Holland',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/holland/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/holland/pic01.png'),
                    require('@/assets/images/partfolio/holland/pic02.png'),
                    require('@/assets/images/partfolio/holland/pic03.png'),
                    require('@/assets/images/partfolio/holland/pic04.png'),
                ]
            },
            {
                name: 'landmark',
                title: 'Landmark',
                desc: 'Сайт-лендинг для ЖК Landmark',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/landmark/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/landmark/pic01.png'),
                    require('@/assets/images/partfolio/landmark/pic02.png'),
                    require('@/assets/images/partfolio/landmark/pic03.png'),
                    require('@/assets/images/partfolio/landmark/pic04.png'),
                ]
            },
            {
                name: 'discovery',
                title: 'Discovery',
                desc: 'Сайт-лендинг для ЖК Discovery',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/discovery/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/discovery/pic01.png'),
                    require('@/assets/images/partfolio/discovery/pic02.png'),
                    require('@/assets/images/partfolio/discovery/pic03.png'),
                    require('@/assets/images/partfolio/discovery/pic04.png'),
                ]
            },
            {
                name: 'riviera',
                title: 'Riviera',
                desc: 'Сайт-лендинг для ЖК Riviera',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/riviera/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/riviera/pic01.png'),
                    require('@/assets/images/partfolio/riviera/pic02.png'),
                    require('@/assets/images/partfolio/riviera/pic03.png'),
                    require('@/assets/images/partfolio/riviera/pic04.png'),
                ]
            },
            {
                name: 'mpark',
                title: 'Millennium Park',
                desc: 'Сайт-лендинг для ЖК Millennium Park',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/mpark/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/mpark/pic01.png'),
                    require('@/assets/images/partfolio/mpark/pic02.png'),
                    require('@/assets/images/partfolio/mpark/pic03.png'),
                    require('@/assets/images/partfolio/mpark/pic04.png'),
                ]
            },
            {
                name: 'legenda',
                title: 'Legenda',
                desc: 'Сайт-лендинг для ЖК Legenda',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/legenda/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/legenda/pic01.png'),
                    require('@/assets/images/partfolio/legenda/pic02.png'),
                    require('@/assets/images/partfolio/legenda/pic03.png'),
                    require('@/assets/images/partfolio/legenda/pic04.png'),
                ]
            },
            {
                name: 'akdidar',
                title: 'Ak-didar',
                desc: 'Сайт-лендинг для ЖК Ak-Didar',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/akdidar/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/akdidar/pic01.png'),
                    require('@/assets/images/partfolio/akdidar/pic02.png'),
                    require('@/assets/images/partfolio/akdidar/pic03.png'),
                    require('@/assets/images/partfolio/akdidar/pic04.png'),
                ]
            },
            {
                name: 'nurlydala',
                title: 'Nurly Dala',
                desc: 'Сайт-лендинг для ЖК Nurly Dala',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: false,
                link_url: null,
                prev_img: require('@/assets/images/partfolio/nurlydala/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/nurlydala/pic01.png'),
                    require('@/assets/images/partfolio/nurlydala/pic02.png'),
                    require('@/assets/images/partfolio/nurlydala/pic03.png'),
                    require('@/assets/images/partfolio/nurlydala/pic04.png'),
                ]
            },
            {
                name: 'concrete-paints',
                title: 'Concrete Paints',
                desc: 'Сайт по продаже строительных красок',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: true,
                link_url: 'https://paints.concrete.kz',
                prev_img: require('@/assets/images/partfolio/concrete-paints/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/concrete-paints/pic01.png'),
                    require('@/assets/images/partfolio/concrete-paints/pic02.png'),
                    require('@/assets/images/partfolio/concrete-paints/pic03.png'),
                    require('@/assets/images/partfolio/concrete-paints/pic04.png'),
                ]
            },
            {
                name: 'concrete-block',
                title: 'Concrete Block',
                desc: 'Сайт по продаже неавтоклавного газобетона',
                desc_full: '',
                desc_anounce: 'Создан и разработан с использованием передовых технологий в web-дизайне и в разработке web-приложений.',
                link: true,
                link_url: 'https://block.concrete.kz',
                prev_img: require('@/assets/images/partfolio/concrete-block/pic-prev.png'),
                rules: {
                    design: true,
                    code: true,
                    responsive: true,
                },
                images: [
                    require('@/assets/images/partfolio/concrete-block/pic01.png'),
                    require('@/assets/images/partfolio/concrete-block/pic02.png'),
                    require('@/assets/images/partfolio/concrete-block/pic03.png'),
                    require('@/assets/images/partfolio/concrete-block/pic04.png'),
                ]
            },
        ]
    },
    getters: {
        loadOpenSendwich: state => state.isOpenSendwich,
        loadPortfolioItems: state => state.portfolioItems,
    },
    actions: {
    },
    mutations: {
        Open_Sendwich(state, data) {
            state.isOpenSendwich = data
        },
    },
})
