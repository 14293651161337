<template>
  <!-- SCRIPTS -->
  <noscript v-if="yandexNoScript != null"><div><img :src="'https://mc.yandex.ru/watch/' + yandexNoScript" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
  <!-- END SCRIPTS -->
  <div class="wrapper">
    <div class="wrapper-in">
      <landscapeMode v-if="iSmobile"></landscapeMode>
      <AppHeaderMobile v-if="iSmobile" />
      <BtnMenu v-if="!iSmobile" />
      <BurgerMenu />
      <AppHeader v-if="!iSmobile" />
      <main
        id="content-layout"
        class="content"
      >
        <router-view />
      </main>
      <AppFooter />
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-cloak
          v-if="openSendwich"
          id="body-overlay"
          class="body-overlay"
          @click="closeMobileMenu()"
        />
      </transition>
    </div>
  </div>
</template>

<script>
    import landscapeMode from '@/components/partials/landscape-mode.vue'

    import AppHeader from '@/components/header/header.vue'
    import AppFooter from '@/components/footer/footer.vue'
    import BtnMenu from '@/components/partials/btn-menu.vue'
    import BurgerMenu from '@/components/partials/menu.vue'

    import AppHeaderMobile from '@/components/header/header-mobile.vue'

    // import mobileSidebar from '@/components/mobile-sidebar/mobile-sidebar.vue'
    import {mapGetters} from 'vuex'
    export default {
        components: {
            landscapeMode,
            AppHeader,
            AppFooter,
            BtnMenu,
            BurgerMenu,
            AppHeaderMobile,
            // mobileSidebar,
        },
        data () {
            return {
                iSmobile: false,
                yandexNoScript: null,
            }
        },
        computed: mapGetters({
            openSendwich: 'loadOpenSendwich',
        }),
        created () {
            this.setNonScriptToBody();
        },
        mounted () {
            this.isMobileCheck();
            this.checkResize();
        },
        methods: {
            closeMobileMenu() {
                this.$store.commit('Open_Sendwich', false);
                document.getElementsByTagName('body')[0].classList.remove('overlay-open');
            },
            // MOBILE CHECKER
            isMobileCheck() {
                if (window.innerWidth < 900) {
                    this.iSmobile = true;
                }
            },
            handleResize() {
                if (window.innerWidth < 900) {
                    this.iSmobile = true
                } else {
                    this.iSmobile = false
                }
            },
            checkResize() {
                window.addEventListener('resize', this.handleResize)
                this.handleResize();
            },
            setNonScriptToBody() {
                this.yandexNoScript = '88746674';
            },
        }
    }
</script>